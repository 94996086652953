/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import { getChargerToQr } from "../../stores/actions/qr_action";

import { button_text } from "../../styles/colors";
import LOGO from "../../assets/images/logo.png";
import KAKAO_LOGO from "../../assets/images/kakao_navi_icon.png";
import E_PIT_LOGO from "../../assets/images/e_pit_icon.png";

const APP_SCHEME = "hyundaiepit://";
const ANDROID_PACKAGE = "com.hyundai.acteev";

const APP_STORE_URL = "https://apps.apple.com/app/id1556412770";
const PLAY_STORE_URL = "https://play.google.com/store/apps/details?id=" + ANDROID_PACKAGE;

const userAgent = navigator.userAgent.toLowerCase();
const isAndroid = userAgent.indexOf("android") > -1;
const isIOS = userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipad") > -1;

//QR 플랫폼 (private : 회원만 접근가능)
const qrPlatforms = [
  {
    name: "seoulev",
    src: LOGO,
    alt: "서울이브이 QR결제",
    route: "/QRpay",
    private: true,
    label: '비회원'
  },
  {
    name: "kakao",
    src: KAKAO_LOGO,
    alt: "KakaoNavi QR결제",
    route: new URL(
      "/web2app/bridge?action=kakaonavi://car_electro?ref=seoul_ev",
      "https://carner.kakaomobility.com"
    ),
    private: false,
    label: "카카오내비",
  },
  {
    name: "E-pit",
    src: E_PIT_LOGO,
    alt: "E-pit 결제",
    route:
      getEpitAppUrl()
    ,
    private: false,
    label: "E-pit",
  },
];


function getEpitAppUrl() {
  // 현재 디바이스 체크
  if (isAndroid) {
    // 안드로이드인 경우
    return `intent://${APP_SCHEME}#Intent;package=${ANDROID_PACKAGE};scheme=https;end`;
  } else if (isIOS) {
    // iOS인 경우
    return APP_SCHEME;
  }
}

function _linkTo(index, qrcode) {
  const obj = qrPlatforms[index];
  if (obj.name === "kakao") {
    obj.route.searchParams.set("data", `{code: ${qrcode}}`);
    return obj.route.href;
  }
  if (obj.name === "E-pit") {
    return obj.route;
  }
  return `${obj.route}/${qrcode}`;
}

function QRplatformPage() {
  const user = useState(useSelector((state) => state.user));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  let [isChargerReady, setIsChargerReady] = useState(false);
  let qrcode = param["qrcode"];
  useEffect(() => {
    if (qrcode) {
      dispatch(getChargerToQr(qrcode))
        .then((response) => {
          if (response.payload.result === "OK") {
            // OK : 조회 성공
            setIsChargerReady(response.payload.data.sttus === "AB" || response.payload.data.sttus === "AA");
          } else {
            // FAIL : 실패
            setIsChargerReady(false);
            alert(response.payload.msg);
          }
        })
        .catch((e) => {
          setIsChargerReady(false);
          alert("비회원 현장결제를 지원하지 않는 충전기입니다.");
        });
    } else {
      navigate("*");
    }
  }, []);

  return (
    <>
      <NavBar isNavBtnVisible={false} />
      <div css={QRpayWrap}>
        <Card>
          <Card.Body>
            <div
              css={css`
                padding: 5%;
              `}
            >
              <ol
                css={css`
                  line-height: 150%;
                  word-break: keep-all;
                `}
              >
                <li>* QR 결제방식 안내</li>
                <br />
                <li>
                  1. 이용하고자 하는 서비스를 선택하여, QR 결제를 진행합니다.
                </li>
                <li>
                  2. 보다 자세한 QR 결제 진행 방법은 각 서비스에 접속하여
                  확인해주시기 바랍니다.
                </li>
              </ol>

              <div
                css={css`
                  width: 100%;
                  display: flex;
                  justify-content: center;
                `}
              >
                <div
                  className="d-flex justify-content-evenly"
                  css={css`
                    padding-top: 5%;
                    max-width: 500px;
                    gap: 15px;
                  `}
                >
                  {Array.from({ length: 3 }).map((_, i) =>
                    qrPlatforms[i] != null ? (
                      <Link
                        to={isChargerReady ? _linkTo(i, qrcode) : null}
                        css={css`
                          display: flex;
                          flex-direction: column;
                        `}
                        onClick={() => {
                          if (qrPlatforms[i].name === "E-pit") {
                            if (isIOS) {
                              setTimeout(() => {
                                window.location.href = APP_STORE_URL;
                              }, 2000);
                            } else {
                              setTimeout(() => {
                                window.location.href = PLAY_STORE_URL;
                              }, 2000);
                            }
                          }
                        }}
                      >
                        <div
                          key={i}
                          css={isChargerReady ? btnActive : btnInactive}
                        >
                          <img
                            src={qrPlatforms[i].src}
                            alt="QR platform logo"
                            css={css`
                              width: 100%;
                              object-fit: contain;
                              object-position: center;
                            `}
                          />
                        </div>
                        <div>
                          {qrPlatforms[i]?.label && (
                            <p
                              css={css`
                                text-align: center;
                                padding-top: 5px;
                              `}
                            >
                              {qrPlatforms[i].label}
                            </p>
                          )}
                        </div>
                      </Link>
                    ) : (
                      <div key={i} css={btnInactive}>
                        <p
                          css={css`
                            word-break: keep-all;
                            text-align: center;
                          `}
                        >
                          서비스 준비중
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div >
    </>
  );

}

const QRpayWrap = css`
  padding: 5%;
  input[type="checkbox"] {
    display: none;
  }
  .card {
    border: none;
    h1 {
      padding-bottom: 32px;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
    }
    .card-body {
      padding: 0;

      .form_row {
        display: flex;
        padding-bottom: 24px;

        .input_div {
          width: 100%;
          height: 48px;
        }

        .label_div {
          font-weight: 400;
          font-size: 16px;
          line-height: 225.3%;
          color: ${button_text};

          min-width: 115px;
        }

        .text_div {
          width: 100%;
        }

        textarea {
          border-radius: 10px;
        }

        input[type="text"] {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
  }
`;

const btnActive = css`
  display: flex;
  justify-content: center;
  place-items: center;
  max-width: 100px;
  padding: 10px;
  border: 1px solid black;
  height: 100px;
`;

const btnInactive = css`
  display: flex;
  justify-content: center;
  place-items: center;
  max-width: 100px;
  padding: 10px;
  background-color: #dedede;
  filter: grayscale(1) opacity(0.6);
  height: 100px;
`;

export default QRplatformPage;
